import { AnyAction, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SearchReqData, SearchRspData } from "../../model/Search";
import { Question } from "../../model/question/Question";
import { QuestionData } from "../../model/question/QuestionData";

export function isSearchAction(action: AnyAction): boolean {
    return action.type.startsWith("search_runtime");
}

export const searchRuntimeSlice = createSlice({
    name: "search_runtime",
    initialState: {
        roomIn: false,
        index: 0,
        searchReqData: new SearchReqData(),
        searchRspData: new SearchRspData(),
        questionID: "",
        question: new Question(),
    },
    reducers: {
        actionCleanSidebar: (state, action: PayloadAction<boolean>) => {
            state.roomIn = false;
            state.index = 0;
            state.questionID = "";
        },
        _actionSetSearchReqData: (state, action: PayloadAction<SearchReqData>) => {
            state.searchReqData = action.payload;
        },
        _actionSetSearchRspData: (state, action: PayloadAction<SearchRspData>) => {
            state.searchRspData = { ...action.payload, is_backend: true };
        },
        actionSetSidebarRoomIn: (state, action: PayloadAction<boolean>) => {
            state.roomIn = action.payload;
        },
        actionSetSidebarIndex: (state, action: PayloadAction<number>) => {
            state.index = action.payload;
        },
        actionSetSearchQuestionID: (state, action: PayloadAction<string>) => {
            state.questionID = action.payload;
        },
        actionSetSearchQuestion: (state, action: PayloadAction<Question>) => {
            state.question = action.payload;
        },
        actionSetSearchQuestionData: (state, action: PayloadAction<QuestionData>) => {
            state.question.data = action.payload;
        },
    },
});

export const {
    actionSetSidebarRoomIn,
    actionSetSidebarIndex,
    actionCleanSidebar,
    actionSetSearchQuestionID,
    _actionSetSearchReqData,
    _actionSetSearchRspData,
    actionSetSearchQuestion,
    actionSetSearchQuestionData,
} = searchRuntimeSlice.actions;

export default searchRuntimeSlice.reducer;
