import { Tag } from "./tag/Tag";

export class FullTags {
    kinds: KindsTags = new KindsTags();
}

export class KindTags {
    kind: number = 0;
    ids: string[] = [];
    tags: Tag[] = [];
}

export class KindsTags {
    [key: number]: KindTags;
}
