import { AuthData } from "../../model/AuthData";
import { User } from "../../model/UserData";
import { AnyAction, PayloadAction, createSlice } from "@reduxjs/toolkit";

export function isUserAction(action: AnyAction): boolean {
    return action.type.startsWith("user");
}

export const userRuntimeSlice = createSlice({
    name: "user",
    initialState: {
        data: new User(),
        login: false,
        auth: {
            user_id: "",
            user_name: "",
            exp: new Date(0),
        },
        editMode: false,
        showAnalysis: false,
        vipDialog: false,
    },
    reducers: {
        actionUpdateUserAuth: (state, action: PayloadAction<AuthData>) => {
            state.auth = action.payload;
        },
        actionSetUserData: (state, action: PayloadAction<User>) => {
            state.data = action.payload;
        },
        actionSetEditMode: (state, action: PayloadAction<boolean>) => {
            state.editMode = action.payload;
        },
        actionSetLogin: (state, action: PayloadAction<boolean>) => {
            state.login = action.payload;
        },
        actionSetShowAnalysis: (state, action: PayloadAction<boolean>) => {
            state.showAnalysis = action.payload;
        },
        actionSetVIPDialog: (state, action: PayloadAction<boolean>) => {
            state.vipDialog = action.payload;
        },
    },
});

export const {
    actionUpdateUserAuth,
    actionSetUserData,
    actionSetEditMode,
    actionSetLogin,
    actionSetShowAnalysis,
    actionSetVIPDialog,
} = userRuntimeSlice.actions;

export default userRuntimeSlice.reducer;
