import { API, apiRequest } from "./api";
import { ResumeLoginRequest, ResumeLoginResponse } from "./apiuser/user";

const apiResumeLogin: API<ResumeLoginRequest, ResumeLoginResponse> = {
    url: "/api/user/resume_login",
};

export function methodResumeLogin(
    dispatch: any,
    req: ResumeLoginRequest
): Promise<ResumeLoginResponse> {
    return apiRequest({ api: apiResumeLogin, req, dispatch, dealErr: true });
}
