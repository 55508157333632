import { Answer } from "../question/Answer";

export class Craft {
    id: string = "";
    user_id: string = "";
    collect: Collect = new Collect();
    wrong: Wrong = new Wrong();
    note: Note = new Note();
}

// Code generated by transtool(v0.0.10).
export class Wrong {
    answer: Answer = new Answer();
    wrong_time_stamp: number = 0;
    wrong_time_str: string = "";
    wrong_from: string = "";
    exam_id: string = "";
}

// Code generated by transtool(v0.0.10).
export class Note {
    text: string = "";
    reasons: NoteReason[] = [new NoteReason()];
    update_time_stamp: number = 0;
    update_time_str: string = "";
    source: string = "";
}

export class NoteReason {
    name: string = "";
    type: number = 0;
    checked: boolean = false;
    hide: boolean = false;
}

// Code generated by transtool(v0.0.10).
export class Collect {
    collected: boolean = false;
    update_time_stamp: number = 0;
    update_time_str: string = "";
    source_name: string = "";
    source_qid: string = "";
}
