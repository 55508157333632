import { useRouteError } from "react-router-dom";
import { LogError } from "../ddlib/tools/log";

export default function ErrorPage() {
    const error = useRouteError();
    LogError(error);

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>{/* <i>{error.statusText || error.message}</i> */}</p>
        </div>
    );
}
