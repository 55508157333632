import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { selectVIPDialog } from "../../../store/user/selector";
import { actionSetVIPDialog } from "../../../store/user/reducer";

export function VIPDialog() {
    const dispatch = useDispatch();

    const open = useSelector(selectVIPDialog);

    const onClose = () => {
        dispatch(actionSetVIPDialog(false));
    };
    const onConfirm = () => {
        dispatch(actionSetVIPDialog(false));
    };
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>获取会员权益</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: "20px", position: "relative" }}>
                    <Button>解锁会员（TODO）</Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} autoFocus>
                    关闭
                </Button>
            </DialogActions>
        </Dialog>
    );
}
