interface GlobalConfig {
  auto_resume_login: boolean;
  api_version: string;
}

function GetGlobalConfig(): GlobalConfig {
  if (process.env.NODE_ENV === "development") {
    return {
      auto_resume_login: true,
      api_version: process.env.REACT_APP_API_VERSION
        ? process.env.REACT_APP_API_VERSION
        : "",
    };
  }
  return {
    auto_resume_login: true,
    api_version: process.env.REACT_APP_API_VERSION
      ? process.env.REACT_APP_API_VERSION
      : "",
  };
}

export default GetGlobalConfig;
