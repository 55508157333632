export enum PageSource {
    None = 0,
    Home = 1, // 首页
    Exam = 2, // 模考
    Material = 3, // 资料下载
    Search = 4, // 搜索
    PT = 5, // pt模考
    Collect = 6, // 收藏夹
    Wrongs = 7, // 错题集
    Note = 8, // 笔记列表
    ByType = 9, // ByType
}
