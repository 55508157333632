// 调整颜色的亮度
export function lightenColor(color: string, amount: number): string {
    let r, g, b;
    if (color.startsWith("#")) {
        [r, g, b] = parseHexColor(color);
    } else {
        [r, g, b] = parseRgbColor(color);
    }

    // 将RGB转换为HSL
    let hslColor = rgbToHsl(r, g, b);

    // 调整亮度
    hslColor[2] = Math.max(0, Math.min(100, hslColor[2] + amount));

    // 将HSL转换回RGB
    const rgbColor = hslToRgb(hslColor[0], hslColor[1], hslColor[2]);

    // 将RGB值转换回十六进制颜色字符串
    const hex = rgbColor
        .map((component) => Math.round(component).toString(16).padStart(2, "0"))
        .join("");

    return `#${hex}`;
}

function parseHexColor(hexColor: string): number[] {
    // 解析十六进制颜色字符串并将其转换为RGB值
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    return [r, g, b];
}

function parseRgbColor(rgbColor: string): number[] {
    const match = rgbColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

    if (match) {
        const r = parseInt(match[1], 10);
        const g = parseInt(match[2], 10);
        const b = parseInt(match[3], 10);

        return [r, g, b];
    }

    return [128, 128, 128];
}

// 辅助函数：将RGB颜色转换为HSL颜色
function rgbToHsl(r: number, g: number, b: number): number[] {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    let l = (max + min) / 2;

    if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r:
                h = ((g - b) / d + (g < b ? 6 : 0)) * 60;
                break;
            case g:
                h = ((b - r) / d + 2) * 60;
                break;
            case b:
                h = ((r - g) / d + 4) * 60;
                break;
        }
    }

    return [h, s * 100, l * 100];
}

// 辅助函数：将HSL颜色转换为RGB颜色
function hslToRgb(h: number, s: number, l: number): number[] {
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;

    let r = 0,
        g = 0,
        b = 0;

    if (h >= 0 && h < 60) {
        r = c;
        g = x;
    } else if (h >= 60 && h < 120) {
        r = x;
        g = c;
    } else if (h >= 120 && h < 180) {
        g = c;
        b = x;
    } else if (h >= 180 && h < 240) {
        g = x;
        b = c;
    } else if (h >= 240 && h < 300) {
        r = x;
        b = c;
    } else if (h >= 300 && h < 360) {
        r = c;
        b = x;
    }

    return [(r + m) * 255, (g + m) * 255, (b + m) * 255];
}
