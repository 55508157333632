import { QuestionData } from "../question/QuestionData";

export class BankData {
    exam_infos: ExamInfo[] = [new ExamInfo()];
    is_backend_data: boolean = false;
}

export class ExamDataOld {
    id: string = "";
    index: string = "";
    name: string = "";
    sections: SectionDataOld[] = [new SectionDataOld()];
}

export class ExamInfo {
    id: string = "";
    index: string = "";
    name: string = "";
    accessible: boolean = false;
    practice_info: PracticeInfo = new PracticeInfo();
}

export const PracticeStatusNotStart = 0;
export const PracticeStatusStart = 1;
export const PracticeStatusPartCompleted = 2;
export const PracticeStatusFinished = 3;

export class PracticeInfo {
    id: string = "";
    exam_id: string = "";
    status: number = 0;
}

export class SectionDataOld {
    name: string = "";
    description: string = "";
    questions: QuestionData[] = [new QuestionData()];
    section_type: string = "";
    time_limit_seconds: number = 0;
}

export class UpdateAnalysisRsp {
    exam: ExamDataOld = new ExamDataOld();
}

export class UpdatePassageRsp {
    exam: ExamDataOld = new ExamDataOld();
}

export class UpdateInquiryRsp {
    exam: ExamDataOld = new ExamDataOld();
}

export class UpdateQuestionRsp {
    exam: ExamDataOld = new ExamDataOld();
}

export class UpdateSectionOrderRsp {
    exam: ExamDataOld = new ExamDataOld();
}
