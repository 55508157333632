import { Box, Button } from "@mui/material";
import AddAssistantImg from "./asset/add_assistant.jpg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { selectPermissionDialogOpen } from "../../../store/root/selector";
import { actionSetPermissionDialog } from "../../../store/root/reducer";

export interface PermissionMsgDialogProps {}

function PermissionMsgDialog(props: PermissionMsgDialogProps) {
    const dispatch = useDispatch();

    const open = useSelector(selectPermissionDialogOpen);

    const onClose = () => {
        dispatch(actionSetPermissionDialog(false));
    };
    const onConfirm = () => {
        dispatch(actionSetPermissionDialog(false));
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>添加小助理</DialogTitle>
            <DialogContent>
                <DialogContentText>扫码添加小助理，激活权限</DialogContentText>
                <Box>
                    <img src={AddAssistantImg} width="400px" alt="微信二维码" />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} autoFocus>
                    好的
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PermissionMsgDialog;
