import { createTheme, PaletteOptions } from "@mui/material";
import { ColorOverrides, Colors, ColorsType, CustomPalette } from "./color";

// palette color
declare module "@mui/material/styles" {
    interface Palette extends CustomPalette {}
    interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/Chip" {
    interface ChipPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/AppBar" {
    interface AppBarPropsColorOverrides extends ColorOverrides {}
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: any) => augmentColor({ color: { main: mainColor } });

export const paletteOptions = Object.keys(Colors).reduce((acc, key) => {
    acc[key as keyof ColorsType] = createColor(Colors[key as keyof ColorsType]);
    return acc;
}, {} as PaletteOptions);

export const ddTheme = createTheme({
    components: {
        MuiButton: {
            defaultProps: {
                variant: "outlined",
            },
            styleOverrides: {
                root: {
                    fontWeight: 700,
                    borderRadius: 0,
                    borderStyle: "double",
                    borderWidth: "2.5px",
                },
            },
        },
    },
    typography: {
        button: {
            textTransform: "none",
        },
        fontFamily: [
            '"Microsoft YaHei"',
            '"微软雅黑"',
            '"Helvetica Neue"',
            "Helvetica",
            '"Hiragino Sans GB"',
            '"STHeitiSC-Light"',
            "Arial",
            "sans-serif",
        ].join(","),
    },
    palette: paletteOptions,
});
