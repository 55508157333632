import { StandardAnswer } from "./Answer";
import { FullTags } from "./FullTags";
import { RichText } from "./RichText";

export class QuestionData {
    id: string = "";
    visibility: number = 0;
    difficulty: number = 0;
    passage: Passage = new Passage();
    inquiry: Inquiry = new Inquiry();
    options: Option[] = [new Option()];
    standard_answer: StandardAnswer = new StandardAnswer();
    group_id: number = 0;
    analysis: Analysis = new Analysis();
    bookmarked: boolean = false;
    full_tags: FullTags = new FullTags();

    accessable: boolean = false;
}

export class Passage {
    text_type: number = 0;
    rich_text: RichText = new RichText();
    fingerprint: string = "";
}

export class Inquiry {
    text_type: number = 0;
    rich_text: RichText = new RichText();
}

export class Option {
    key: string = "";
    text: string = "";
}

export class Analysis {
    text: RichText = new RichText();
    accessible: boolean = false;
}
