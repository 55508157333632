export class TemplateParam {
    select_ids: SelectIDs = new SelectIDs();
    simulate: Simulate = new Simulate();
}

export class SelectIDs {
    ids: string[] = [];
    remove_correct_from_wrongs: boolean = false;
}

export enum SimulateRangeKind {
    None = 0,
    All = 1,
    Senior = 2,
}

export class Simulate {
    range_kind: number = SimulateRangeKind.All;
    include_done: boolean = false;
    exclude_no_score_section: boolean = false;
}
