import { TemplateParam } from "./TemplateParam";

export class BookTemplate {
    id: string = "";
    index: number = 0;
    order: number = 0;
    kind: number = 0;
    kind2: number = 0;
    kind3: number = 0;
    kind4: number = 0;
    hide: boolean = false;
    param: TemplateParam = new TemplateParam();
}

export class TemplateInfo {
    id: string = "";
    name: string = "";
    kind: number = 0;
    kind2: number = 0;
    kind3: number = 0;
    kind4: number = 0;
}

export enum BookKind {
    None = 0,
    PT = 1,
    Practice = 2,
    Simulate = 3,
}

export enum BookLimit {
    Hide = 0, // 隐藏: 不可见
    Forbid = 1, // 禁止: 可见, 无权限, 点击展示二维码
    Lock = 2, // 锁定: 可见, 有/无权限, 背景灰色, 不可点击按钮（开启考试）
    Prevent = 3, // 限制: 可见, 有/无权限, 背景彩色, 不可点击按钮
    Free = 4, // 开放: 可见, 有权限, 解锁, 背景彩色, 可正常操作
}

export enum BookStatus {
    NotOpen = 0,
    Start = 1,
    Finish = 2,
}

export enum PracticeKind {
    None = 0,
    Collect = 1,
    Wrongs = 2,
    Note = 3,
}
