// Code generated by transtool(v0.0.10).
export class Tag {
    id: string = "";
    label: string = "";
    hide: boolean = false;
    kind: number = 0;
    url: string = "";
    constructor(label: string = "") {
        this.label = label;
    }
}

export class BackendTagBank {
    tags: Tag[] = [new Tag()];
}

export class TagBank {
    is_backend_data: boolean = false;
    tagMap: Map<string, Tag> = new Map(); // key:id
}
