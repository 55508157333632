import { createBrowserRouter } from "react-router-dom";
import "./index.css";
import ErrorPage from "./page/ErrorPage";

export const ddRouter = createBrowserRouter([
    {
        path: "/",
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                async lazy() {
                    let { PTListPage } = await import("./page/nav/pages/pt/PTListPage");
                    return { Component: PTListPage };
                },
            },
            {
                path: "login",
                async lazy() {
                    let { LoginPage } = await import("./page/LoginPage");
                    return { Component: LoginPage };
                },
            },
            {
                path: "exam/:examID",
                async lazy() {
                    let { ExamPage, examLoader } = await import("./page/exam/ExamPage");
                    return { Component: ExamPage, loader: examLoader };
                },
            },
            {
                path: "web_manage",
                async lazy() {
                    let { WebManagePage } = await import("./page/manage/WebManage");
                    return { Component: WebManagePage };
                },
            },
            {
                path: "nav",
                children: [
                    {
                        path: "home",
                        async lazy() {
                            let { HomePage } = await import("./page/nav/pages/home/HomePage");
                            return { Component: HomePage };
                        },
                    },
                    {
                        path: "search",
                        async lazy() {
                            let { SearchPage } = await import("./page/nav/pages/search/SearchPage");
                            return { Component: SearchPage };
                        },
                    },
                    {
                        path: "pt",
                        async lazy() {
                            let { PTListPage } = await import("./page/nav/pages/pt/PTListPage");
                            return { Component: PTListPage };
                        },
                    },
                    {
                        path: "bytype",
                        async lazy() {
                            let { ByTypePage } = await import("./page/nav/pages/bytype/ByTypePage");
                            return { Component: ByTypePage };
                        },
                    },
                    {
                        path: "material",
                        async lazy() {
                            let { MaterialPage } = await import(
                                "./page/nav/pages/material/MaterialPage"
                            );
                            return { Component: MaterialPage };
                        },
                    },
                    {
                        path: "personal",
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    let { CollectPage } = await import(
                                        "./page/nav/pages/personal/CollectPage"
                                    );
                                    return { Component: CollectPage };
                                },
                            },
                            {
                                path: "collect",
                                async lazy() {
                                    let { CollectPage } = await import(
                                        "./page/nav/pages/personal/CollectPage"
                                    );
                                    return { Component: CollectPage };
                                },
                            },
                            {
                                path: "note",
                                async lazy() {
                                    let { NotePage } = await import(
                                        "./page/nav/pages/personal/NotePage"
                                    );
                                    return { Component: NotePage };
                                },
                            },
                            {
                                path: "wrongs",
                                async lazy() {
                                    let { WrongsPage } = await import(
                                        "./page/nav/pages/personal/WrongsPage"
                                    );
                                    return { Component: WrongsPage };
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);
