import { RootState } from "../ReduxStore";

export const _selectUserRuntime = (state: RootState) => state.userRuntime;

export const selectUserAuth = (state: RootState) => _selectUserRuntime(state).auth;
export const selectHasToken = (state: RootState) =>
    _selectUserRuntime(state).auth.user_id.length > 0;

export const selectLogin = (state: RootState) => _selectUserRuntime(state).login;

export const selectEditMode = (state: RootState) => _selectUserRuntime(state).editMode;

export const selectUser = (state: RootState) => _selectUserRuntime(state).data;
export const selectUserID = (state: RootState) => selectUser(state).id;
export const selectIsAdmin = (state: RootState) => selectUser(state).is_admin;

export const selectVIPDialog = (state: RootState) => _selectUserRuntime(state).vipDialog;
