export class User {
    id: string = "";
    name: string = "";
    is_admin: boolean = false;
    access_level: number = 0;
    authority = new Authority();
}

export class UserInfo {
    id: string = "";
    name: string = "";
    label: string = "";
}

export class UserInfoBank {
    infos: UserInfo[] = [];
    isBackend: boolean = false;
}

export class Authority {
    role_name: string = "";
    role_kind: number = 0;
    expire_date: string = "";
}
