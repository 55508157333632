import { QuestionInfo } from "./question/QuestionInfo";

export class SearchReqData {
    search_option: SearchOption = new SearchOption();
    page_control: PageControl = new PageControl();
}

export class SearchRspData {
    is_backend_data: boolean = false;
    question_infos: QuestionInfo[] = [new QuestionInfo()];
    page_control: PageControl = new PageControl();
    total_count: number = 0;
    is_backend: boolean = false;
}

export class SearchOption {
    key_words: string = "";
    visible: number = 0;
    difficulty: number = 0;
    question_ids: string[] = [];
    tag_ids: string[] = [];
    craft: CraftSearchOption = new CraftSearchOption();
}

export class CraftSearchOption {
    filter_collected: boolean = false;
    filter_noted: boolean = false;
    filter_wrong: boolean = false;
}

export class PageControl {
    page_size: number = 0;
    page_index: number = 0;
    total_page: number = 0;
    limit: number = 0;
}
